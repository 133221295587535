import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { FaPen } from 'react-icons/fa6';
import CurrencyLabel from '../shared/CurrencyLabel'
import HighlightLabel from '../shared/HighlightLabel'
import UserContext from '../../context/UserContext';
import { LargerFont } from '../shared/Constants';
import IconLink from '../shared/IconLink';
import RolePermissionContainer from '../shared/RolePermissionContainer';
import AccountContext from '../../context/AccountContext';

function TransactionCard({ transaction, className }) {
  const {transactionBaseCurrency, colorTheme} = useContext(UserContext)

  return (
    <div className={`${className} mb-8 lg:mb-4`}>
      <div className='flex flex-col md:flex-row'>
        <div className='flex items-center flex-wrap'>
          {transaction.is_special && (
            <HighlightLabel theme='purple' className='mr-2 my-1'>Special</HighlightLabel>
          )}

          {transaction.is_prepaid && (
            <HighlightLabel theme='purple' className='mr-2 my-1'>Prepaid</HighlightLabel>
          )}

          <HighlightLabel theme='green' className='mr-2 my-1'>{transaction.account_name}</HighlightLabel>

          {transaction.tagNameList && transaction.tagNameList.length > 0 && transaction.tagNameList.map((item, index) => (
            <HighlightLabel key={index} theme='gray' className='mr-2 my-1'>{item}</HighlightLabel>
          ))}

          {transaction.note && transaction.note !== '' && (
            <HighlightLabel theme='blue' className='mr-2 my-1'>{transaction.note}</HighlightLabel>
          )}  

        </div>
        <div className='ml-auto flex items-center mt-2 md:mt-0'>
          <CurrencyLabel 
            className={`${LargerFont} mr-4`}
            currency={transaction.currency} 
            value={transaction.type === "Income" ? transaction.amount : transaction.amount * -1} 
            maximumFractionDigits={2}
            showBaseCurrency={true}
            currencyDate={transaction.transaction_date}
            baseCurrency={transactionBaseCurrency}
          />

          <RolePermissionContainer permission={'edit-transaction'}>
            <IconLink
              url={`/transactions/edit/${transaction.id}`} 
              left={<FaPen/>}
              right='Edit'
            />
          </RolePermissionContainer>
        </div>
      </div>
    </div>
  )
}

export default TransactionCard