import React, { useContext } from 'react'
import UserContext from '../../context/UserContext'
import { SmallerFont } from './Constants'

function HighlightLabel({children, className, theme = 'default'}) {
  const {colorTheme} = useContext(UserContext)

  let bgColor = ''
  let textColor = ''
  if (theme === 'default') {
    bgColor = colorTheme.default_bg_color
    textColor = colorTheme.secondary_text_color
  }
  if (theme === 'primary') {
    bgColor = colorTheme.primary_bg_color
    textColor = colorTheme.inverted_text_color
  }
  if (theme === 'red') {
    bgColor = colorTheme.red_bg_color
    textColor = colorTheme.red_text_color
  }
  if (theme === 'green') {
    bgColor = colorTheme.green_bg_color
    textColor = colorTheme.green_text_color
  }
  if (theme === 'black') {
    bgColor = colorTheme.black_bg_color
    textColor = colorTheme.inverted_text_color
  }
  if (theme === 'gray') {
    bgColor = colorTheme.secondary_bg_color
    textColor = colorTheme.secondary_text_color
  }
  if (theme === 'purple') {
    bgColor = colorTheme.purple_bg_color
    textColor = colorTheme.purple_text_color
  }
  if (theme === 'blue') {
    bgColor = colorTheme.blue_bg_color
    textColor = colorTheme.blue_text_color
  }
  return (
    <>
      <div className={`px-2 py-0.5 ${bgColor} ${textColor} rounded ${SmallerFont} ${className}`}>{children}</div>
    </>
  )
}

export default HighlightLabel