import React, { useContext, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { toast } from 'react-toastify'
import UserContext from '../../context/UserContext'
import Card from '../shared/Card'
import { LargerFont } from '../shared/Constants'
import PrimaryButton from '../shared/PrimaryButton'
import IconLabel from '../shared/IconLabel'
import { FaCheck } from 'react-icons/fa'
import ActorContext from '../../context/ActorContext'


function BulkHidden() {
  const {colorTheme} = useContext(UserContext)
  const {isActorExists, addActor} = useContext(ActorContext)
  const [textInput, setTextInput] = useState('')


  const onSave = async () => {
    if (textInput === '') {
      return
    }

    const javIdList = textInput.split('\n')
    javIdList.forEach(async (javIdString) => {
      let javId = javIdString
      if (javIdString.includes(' ')) {
        javId = javIdString.split(' ')[0]
      }
      if (javId !== '') {
        const newActor = {
          id: uuidv4(),
          number_id: '',
          name_ch: '',
          name_en: '',
          version: 1,
          type: 'Hidden',
          note: '',
          last_review_month: '',
          last_review_movie: '',
          last_review_movie_release_month: '',
          jav_id: javId,
          is_west: false,
          is_pretty: false,
          is_mature: false,
          is_classic: false,
          is_body: false,
          image_url: '',
          download_status: '',
          career_status: '',
          update_frequency: '',
          missav_url: ''
        }

        if (isActorExists(javId)) {
          toast.error('Duplicate Actor: ' + javId)
        } else {
          const result = await addActor(newActor)
  
          if (!result) {
            toast.error('Cannot add hidden actor: ' + javId)
          } else {
            toast.success('Success: Create Hidden Actor: ' + javId)
          }
        }        
      }    
    })

    
  }

  return (
    <Card>
      <div className={`${LargerFont} mb-4`}>Bulk Hidden Actor</div>

      <div className='mb-2'>Jav ID Only. Ignore everything after first space</div>
      <textarea 
        name="bulkHiddenActor" 
        id="bulkHiddenActor" 
        rows="15" 
        className={`${colorTheme.default_bg_color} border-2 ${colorTheme.secondary_border_color} focus:outline-none focus:${colorTheme.primary_border_color} px-3 py-2 w-full rounded`} 
        value={textInput} 
        onChange={(e) => setTextInput(e.target.value)} />
      <div className="mb-4"></div>

      <div className="flex items-center">
        <PrimaryButton onClick={onSave}>
          <IconLabel
            left={<FaCheck />}
            right='Save'
          />
        </PrimaryButton>
      </div>
    </Card>
  )
}

export default BulkHidden