import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment'
import Header from '../components/shared/Header';
import Navbar from '../components/shared/Navbar';
import PrimaryButton from '../components/shared/PrimaryButton';
import TransactionContext from '../context/TransactionContext';
import Radiobox from '../components/shared/Radiobox';
import Inputbox from '../components/shared/Inputbox';
import { toast } from 'react-toastify';
import Fuse from 'fuse.js'
import TransactionCardGroup from '../components/transactions/TransactionCardGroup';
import TransactionTotalWidget from '../components/transactions/TransactionTotalWidget';
import { SmallerFont } from '../components/shared/Constants';
import UserContext from '../context/UserContext';
import RolePermissionContainer from '../components/shared/RolePermissionContainer';
import DataDependencyContext from '../context/DataDependencyContext';
import IconLabel from '../components/shared/IconLabel';
import { FaPlus } from 'react-icons/fa';
import HighlightLabel from '../components/shared/HighlightLabel';

function TransactionsPage() {
  const navigate = useNavigate();

  const getDateRange = (profile) => {
    switch (profile) {
      case 'currentMonth': 
        return {
          start: moment().startOf('month').format('YYYY-MM-DD'),
          end: moment().format('YYYY-MM-DD')
        }
      case 'previousMonth': 
        return {
          start: moment().startOf('month').subtract(1, 'months').format('YYYY-MM-DD'),
          end: moment().startOf('month').subtract(1, 'days').format('YYYY-MM-DD')
        }
      case 'currentYear': 
        return {
          start: moment().startOf('year').format('YYYY-MM-DD'),
          end: moment().format('YYYY-MM-DD')
        }
      case 'previousYear': 
        return {
          start: moment().subtract(1, 'years').format('YYYY-01-01'),
          end: moment().startOf('year').subtract(1, 'days').format('YYYY-MM-DD')
        }
      default: 
        return {
          start: '',
          end: ''
        }
    }
  }

  const {transactionList, createViewModel, isFullTransactionDataLoaded} = useContext(TransactionContext)
  const {colorTheme} = useContext(UserContext)
  const {isFullDataLoaded} = useContext(DataDependencyContext)
  const [searchParams, setSearchParams] = useSearchParams()
  
  const [isLoading, setIsLoading] = useState(true)

  const [filteredViewModelList, setFilteredViewModelList] = useState([])

  const [filterStartDate, setFilterStartDate] = useState(getDateRange('currentMonth').start)
  const [filterEndDate, setFilterEndDate] = useState(getDateRange('currentMonth').end)
  const [filterDateProfile, setFilterDateProfile] = useState('currentMonth')
  const [isCustomFilterDate, setIsCustomFilterDate] = useState(false)

  const [groupedDateList, setGroupedDateList] = useState([])
  const [groupedTransactionList, setGroupedTransactionList] = useState([])

  const [searchWord, setSearchWord] = useState('')
  const [searchedViewModelList, setSearchedViewModelList] = useState([])

  const [pageParams, setPageParams] = useState({
    start_date: '',
    end_date: ''
  });

  useEffect(() => {
    if (isFullDataLoaded) {
      if (transactionList.length > 0) {
        // if date_profile is not specified
        // set to currentMonth if both start and end date are not specified
        // Otherwise, set to custom
        const dateProfile = searchParams.get("date_profile") || ((searchParams.get("start_date") || searchParams.get("end_date")) ? 'custom' : 'currentMonth')

        const tempPageParams = {
          start_date: searchParams.get("start_date") || moment().startOf('month').format('YYYY-MM-DD'),
          end_date: searchParams.get("end_date") || moment().format('YYYY-MM-DD'),
          date_profile: dateProfile
        }
        setPageParams(tempPageParams)

        setIsCustomFilterDate(tempPageParams.date_profile === 'custom')
        setFilterStartDate(tempPageParams.start_date)
        setFilterEndDate(tempPageParams.end_date)
        setFilterDateProfile(tempPageParams.date_profile)
      }

      setIsLoading(false)      
    }    
  }, [isFullDataLoaded, transactionList, searchParams])

  useEffect(() => {
    const startDate = pageParams.start_date
    const endDate = pageParams.end_date

    let tempTransactionList = [...transactionList]

    tempTransactionList = tempTransactionList.filter(t => 
      t.transaction_date >= startDate && t.transaction_date <= endDate)

    let viewModelList = tempTransactionList.map((t, index) => {
      const vm = createViewModel(t)
      return vm
    })

    setFilteredViewModelList(viewModelList)
  }, [pageParams])

  useEffect(() => {
    if (!searchedViewModelList || searchedViewModelList.length === 0) {
      setGroupedDateList([])
      setGroupedTransactionList([])
    }
    if (searchedViewModelList && searchedViewModelList.length > 0) {
      // Get unique date first
      let localUniqueDateList = searchedViewModelList.reduce((total, item) => {
        return total.includes(item.transaction_date) ? total : [...total, item.transaction_date]
      }, [])
      localUniqueDateList.sort((a, b) => {
        if (a !== b) {
          return a > b ? -1 : 1
        }
        return 0
      })

      let localGroupedTransactionList = []
      localUniqueDateList.forEach((date, index) => {
        let dailyTransactionList = searchedViewModelList.filter((t) => {
          return t.transaction_date === date
        })
        dailyTransactionList.sort((a, b) => {
          if (a.type !== b.type) {
            return a.type === 'Income' ? -1 : 1
          }
          if (a.account_name !== b.account_name) {
            return a.account_name.localeCompare(b.account_name)
          }
          if (a.amount !== b.amount) {
            return a.amount > b.amount ? -1 : 1
          }
          return 0
        })
        localGroupedTransactionList.push(dailyTransactionList)
      })

      setGroupedDateList(localUniqueDateList)
      setGroupedTransactionList(localGroupedTransactionList)
    }
  }, [searchedViewModelList])

  useEffect(() => {
    let tempVMList = []
    if (searchWord === '') {
      tempVMList = [...filteredViewModelList]
    } else {
      const fuseOptions = {
        // isCaseSensitive: false,
        // includeScore: false,
        // shouldSort: true,
        // includeMatches: false,
        // findAllMatches: false,
        // minMatchCharLength: 1,
        // location: 0,
        // threshold: 0.6,
        // distance: 100,
        // useExtendedSearch: false,
        // ignoreLocation: false,
        // ignoreFieldNorm: false,
        // fieldNormWeight: 1,
        includeMatches: true,
        minMatchCharLength: 2,
        threshold: 0.1,
        useExtendedSearch: true,
        keys: [
          "account_name",
          "tagNameList",
          "note",
          "transaction_date",
          "attribute"
        ]
      };
      
      const fuse = new Fuse(filteredViewModelList, fuseOptions);

      const searchResult = fuse.search(searchWord)
      tempVMList = searchResult.map(r => r.item)
    }

    setSearchedViewModelList(tempVMList)
    
  }, [searchWord, filteredViewModelList])

  const updateDateRange = (profile, customStart, customEnd) => {
    let tempStart = ''
    let tempEnd = ''
    if (profile === 'custom') {
      tempStart = customStart
      tempEnd = customEnd
    } else {
      const { start, end } = getDateRange(profile)
      tempStart = start
      tempEnd = end
    }    

    // Only set the search param, which will then set corresponding pageParams and local states
    setSearchParams(prev => {
      prev.set("start_date", tempStart)
      prev.set("end_date", tempEnd)
      prev.set("date_profile", profile)
      return prev
    }, {replace: false})
  }

  const onSaveCustomDate = (e) => {
    e.preventDefault()

    if (filterStartDate > filterEndDate) {
      toast.error('ERROR Start data cannot be later than End date')
      return
    }

    updateDateRange('custom', filterStartDate, filterEndDate)
  }

  if (isLoading) {
    return (
      <>
        <Header title='Transactions' />
        <Navbar currentPage='Transactions' />
        <div className="text-xl">Loading ...</div>
      </>
    )
  }

  return (
    <>
      <Header title='Transactions' />
      <Navbar currentPage='Transactions' />

      <TransactionTotalWidget 
        transactionViewModelList={searchedViewModelList} 
        startDate={filterStartDate}
        endDate={filterEndDate}
      />

      <RolePermissionContainer permission={'create-transaction'}>
        <PrimaryButton onClick={e => navigate("/transactions/add")}>
          <IconLabel
            left={<FaPlus />}
            right='Transaction'
          />
        </PrimaryButton>      
      </RolePermissionContainer>
      <div className="mb-8"></div>

      <div className="mb-8">
        <Inputbox 
          name='searchWord'
          id='searchWord'
          placeholder='Search'
          value={searchWord}
          onChange={(e) => {setSearchWord(e.target.value)}} 
          annotation="Advanced Syntax: (1) =exact, (2) 'include, (3) ^start, (4) .end$"
        />
      </div>

      <div className='grid grid-cols-1 lg:grid-cols-3 gap-4'>
        <div>
          <div className="font-bold">Filter by Date</div>

          {!isCustomFilterDate && (
            <div className={`mb-1 ${SmallerFont} ${colorTheme.secondary_text_color}`}>Selection: {filterStartDate} - {filterEndDate}</div>
          )}    
          <div>
            <Radiobox 
              label='Current Month'
              name='currentMonth'
              id='currentMonth'
              checked={filterDateProfile === 'currentMonth'} 
              onChange={(e) => updateDateRange(e.target.id)} 
            />
          </div>
          <div>
            <Radiobox 
              label='Previous Month'
              name='previousMonth'
              id='previousMonth'
              checked={filterDateProfile === 'previousMonth'} 
              onChange={(e) => updateDateRange(e.target.id)} 
            />
          </div>
          <div>
            <Radiobox 
              label='Current Year'
              name='currentYear'
              id='currentYear'
              checked={filterDateProfile === 'currentYear'} 
              onChange={(e) => updateDateRange(e.target.id)} 
            />
          </div>
          <div>
            <Radiobox 
              label='Previous Year'
              name='previousYear'
              id='previousYear'
              checked={filterDateProfile === 'previousYear'} 
              onChange={(e) => updateDateRange(e.target.id)} 
            />
          </div>    
          <div>
            <Radiobox 
              label='Custom'
              name='custom'
              id='custom'
              checked={filterDateProfile === 'custom'} 
              onChange={(e) => {
                setFilterDateProfile('custom')
                setIsCustomFilterDate(true)}
              } 
            />
          </div>  
          {isCustomFilterDate && (
            <>
            <div className='grid grid-cols-2 gap-2 mt-2'>
              <div>
              <Inputbox 
                name='startDate'
                id='startDate'
                type='date'
                placeholder='Start Date'
                value={filterStartDate}
                onChange={(e) => {setFilterStartDate(e.target.value)}} 
              />
              </div>
              <div>
              <Inputbox 
                name='endDate'
                id='endDate'
                type='date'
                placeholder='End Date'
                value={filterEndDate}
                onChange={(e) => {setFilterEndDate(e.target.value)}} 
              />
              </div>
            </div>
            <div className="flex items-center">
              <PrimaryButton onClick={onSaveCustomDate} className={`ml-auto`}>Filter Date</PrimaryButton>
            </div>
            </>
          )}
        </div>
      </div>

      <div className="mb-8"></div>
      <div>Showing: {searchedViewModelList.length} transactions</div>

      <div className="mb-2"></div>
      <div className='flex items-center'>
        <div className="mr-2">Historical Data Status:</div>
        {isFullTransactionDataLoaded ? <HighlightLabel theme='green'>Loaded</HighlightLabel> : <HighlightLabel theme='red'>Loading...</HighlightLabel>}
      </div>

      <div className="mb-8"></div>
      {groupedDateList.map((item, index) => (
        <TransactionCardGroup key={index} date={item} transactionList={groupedTransactionList[index]} />
      ))}
    </>
    
  )
}

export default TransactionsPage