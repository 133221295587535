import React, { useContext, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { toast } from 'react-toastify'
import UserContext from '../../context/UserContext'
import Card from '../shared/Card'
import { LargerFont } from '../shared/Constants'
import PrimaryButton from '../shared/PrimaryButton'
import SupabaseContext from '../../context/SupabaseContext'
import IconLabel from '../shared/IconLabel'
import { FaCheck } from 'react-icons/fa'


function BulkIgnore() {
  const {supabaseClient} = useContext(SupabaseContext)
  const {userId, colorTheme} = useContext(UserContext)
  const [textInput, setTextInput] = useState('')


  const onSave = async () => {
    if (textInput === '') {
      return
    }

    const movieIdList = textInput.split('\n')
    movieIdList.forEach(async (movieIdString) => {
      let movieId = movieIdString
      if (movieIdString.includes(' ')) {
        movieId = movieIdString.split(' ')[0]
      }
      if (movieId !== '') {
        const newMovie = {
          'id': movieId,
          'note': '',
          'user_id': userId
        }
  
        const {error } = await (await supabaseClient())
          .from('movie_movie')
          .insert(newMovie)
  
        if (error && error.code !== '') {
          toast.error('Cannot save movie: ' + error.message)
        } else {
          const newMovieCategory = {
            'id': uuidv4(),
            'movie_id': movieId,
            'category_id': 'f1999eb7-37ce-4154-a6b1-93f0de6ff5a4',
            'user_id': userId
          }
          await (await supabaseClient())
            .from('movie_movie_r_category')
            .insert(newMovieCategory)
        }     
      }    
    })

    toast.success('Success: Save Ignore Movie')
  }

  return (
    <Card>
      <div className={`${LargerFont} mb-4`}>Bulk Ignore</div>

      <div className='mb-2'>Movie ID Only. Ignore everything after first space</div>
      <textarea 
        name="bulkIgnore" 
        id="bulkIgnore" 
        rows="15" 
        className={`${colorTheme.default_bg_color} border-2 ${colorTheme.secondary_border_color} focus:outline-none focus:${colorTheme.primary_border_color} px-3 py-2 w-full rounded`} 
        value={textInput} 
        onChange={(e) => setTextInput(e.target.value.toUpperCase())} />
      <div className="mb-4"></div>

      <div className="flex items-center">
        <PrimaryButton onClick={onSave}>
          <IconLabel
            left={<FaCheck />}
            right='Save'
          />
        </PrimaryButton>
      </div>
    </Card>
  )
}

export default BulkIgnore