import React, { useContext } from 'react'
import { createContext, useState, useEffect } from 'react'
import moment from 'moment/moment'
import UserContext from './UserContext'
import SupabaseContext from './SupabaseContext'

const OrderContext = createContext()

export const OrderProvider = ({children}) => {
  const {supabaseClient} = useContext(SupabaseContext)
  const {userId} = useContext(UserContext);
  const [orderList, setOrderList] = useState([])
  const [isOrderLoaded, setIsOrderLoaded] = useState(false)

  const [stockList, setStockList] = useState([])
  const [isStockLoaded, setIsStockLoaded] = useState(false)

  const _fetchData = async () => {
    const { data, error } = await (await supabaseClient())
      .from('order_v2')
        .select(`*,
        stock_v2 (*),
        trade_v2 (*, account_v2(*)),
        dividend_v2 (*, account_v2(*))
      `)
      .eq('user_id', userId)
    
    let result = []

    data.forEach((order, index) => {
      let newOrder = _processOrder(order)
      // console.log("Order", JSON.stringify(newOrder, null, 4))
      result.push(newOrder)
    })

    result.sort(_sortOrder)

    // console.log('Order Context', result);

    setOrderList(result)

    setIsOrderLoaded(true)
    //console.log('result', result)
  }

  const _fetchStock = async () => {
    const { data, error } = await (await supabaseClient())
      .from('stock_v2')
      .select(`*`)
      .eq('user_id', userId)
    
    setStockList(data)
    setIsStockLoaded(true)
  }


  useEffect(() => {
    if (userId) {
      _fetchData()    
      _fetchStock()
    }    
  }, [userId])

  const _loadSingleFromDB = async (orderId) => {
    const { data, error } = await (await supabaseClient())
      .from('order_v2')
      .select(`*,
        stock_v2 (*),
        trade_v2 (*, account_v2(*)),
        dividend_v2 (*, account_v2(*))
      `)
      .eq('user_id', userId)
      .eq('id', orderId)
    
    let newOrder = _processOrder(data[0])
    return newOrder
  }

  const _processOrder = (order) => {
    // remove _v2 naming
    let resultOrder = _renameProperty(order)

    let sortedTradeList = [...resultOrder.trade]
    sortedTradeList.sort(_sortTradeAsc)
    sortedTradeList.forEach((trade, tradeIndex) => {
      const matchingTradeList = _findMatchingTrade(trade, sortedTradeList)
      trade.matching_trade = matchingTradeList

      const tradeAnalytics = _calculateTradeAnalytics(trade, sortedTradeList)
      trade.analytics = tradeAnalytics

      // set currency from any trade
      resultOrder.currency = trade.account.currency
    })

    sortedTradeList.sort(sortTradeDesc)
    resultOrder.trade = sortedTradeList

    // non-matching trade list for unrealized return
    const nonMatchingTradeList = _findNonMatchingTradeList(sortedTradeList)
    resultOrder.non_matching_trade = nonMatchingTradeList

    const orderAnalytics = _calculateOrderAnalytics(resultOrder)
    resultOrder.analytics = orderAnalytics

    // Final sort - from new to old
    if (resultOrder.dividend && resultOrder.dividend.length > 0) {
      let dividendList = [...resultOrder.dividend]
      dividendList.sort(sortDividend)
      resultOrder.dividend = dividendList
    }

    // Add attribute for searching
    resultOrder.attribute = orderAnalytics.is_active ? 'Active' : 'Closed'    

    return resultOrder
  }

  const _renameProperty = (order) => {
    let newOrder = {
      id: order.id,
      note: order.note,
      stock_id: order.stock_id,
      user_id: order.user_id,
      dividend: order.dividend_v2,
      stock: order.stock_v2,
      trade: order.trade_v2
    }

    if (newOrder.dividend && newOrder.dividend.length > 0) {
      newOrder.dividend.forEach((dividend) => {
        dividend.account = dividend.account_v2
        delete dividend.account_v2
      })
    }

    if (newOrder.trade && newOrder.trade.length > 0) {
      newOrder.trade.forEach((trade) => {
        trade.account = trade.account_v2
        delete trade.account_v2
      })
    }
    return newOrder
  }

  const _calculateTradeAnalytics = (trade, tradeList) => {
    
    let realizedPnL = 0.0
    let realizedYield = 0.0
    let cost = 0.0

    let saleProcessings = 0.0

    if (trade.buy_sell === 'Sell') {
      
      saleProcessings = trade.position * trade.price - trade.commission

      if (trade.matching_trade && trade.matching_trade.length > 0) {
        trade.matching_trade.forEach((item, index) => {
  
          const matchingTrade = tradeList.filter((t) => t.id === item.id)[0]
  
          let tradeCost = (matchingTrade.position * matchingTrade.price + matchingTrade.commission) * (item.proportion / item.position)
          cost += tradeCost
        })
      }
      realizedPnL = saleProcessings - cost
      realizedYield = realizedPnL / cost
    } else if (trade.buy_sell === 'Buy') {
      cost = trade.position * trade.price + trade.commission
    }

    
    return {
      'cost': cost,
      'realized_pnl': realizedPnL,
      'realized_yield': realizedYield,
      'sale_processings': saleProcessings
    }
  }

  const _calculateOrderAnalytics = (order) => {
    let realizedPnL = 0.0  // Realized PnL, exclude dividend
    let realizedYield = 0.0
    let unrealizedPnL = 0.0
    let unrealizedYield = 0.0
    let unrealizedBuyingCost = 0.0
    let totalBuyingCost = 0.0  // Total buying cost
    let totalSellTradeBuyingCost = 0.0  // Total buying cost of selling trade only
    let totalSaleProceedings = 0.0  // Total proceedings of sell trades
    let breakevenPrice = 0.0
    let isActive = false
    let totalBuyPosition = 0.0
    let totalSellPosition = 0.0
    let totalDividend = 0.0
    let totalTradeCommission = 0.0
    let totalDividendCommission = 0.0
    let totalRealizedPnL = 0.0 // Total realized PnL, include dividend
    let totalRealizedYield = 0.0
    let breakevenPriceWithDividend = 0.0

    // Realized metric based on trade
    if (order.trade.length > 0) {
      order.trade.forEach((trade) => {
        if (trade.buy_sell === 'Buy') {
          totalBuyingCost += trade.analytics.cost
          totalBuyPosition += trade.position
        } else {
          totalSellTradeBuyingCost += trade.analytics.cost
          realizedPnL += trade.analytics.realized_pnl
          totalSaleProceedings += trade.analytics.sale_processings
          totalSellPosition += trade.position
        }
        totalTradeCommission += trade.commission
      })
      if (totalSellTradeBuyingCost > 0.0) {
        realizedYield = realizedPnL / totalSellTradeBuyingCost
      }
      
      isActive = totalBuyPosition !== totalSellPosition
    }

    // Unrealized metric based on market price
    const marketPrice = order.stock.market_price
    let marketValue = 0.0
    if (marketPrice && marketPrice > 0.0) {
      if (order.non_matching_trade.length > 0) {

        order.non_matching_trade.forEach((item) => {
          const nonMatchingTrade = order.trade.filter((t) => t.id === item.id)[0]
          const buyingCost = nonMatchingTrade.position * nonMatchingTrade.price + nonMatchingTrade.commission
          const ratio = item.proportion / item.position
          unrealizedPnL += marketPrice * item.proportion - buyingCost * ratio
          unrealizedBuyingCost += buyingCost * ratio
        })
        if (unrealizedBuyingCost > 0.0) {
          unrealizedYield = unrealizedPnL / unrealizedBuyingCost
        }
      }

      if (isActive) {
        marketValue = marketPrice * (totalBuyPosition - totalSellPosition)
      }
    }

    // breakeven price
    if (isActive) {
      breakevenPrice = (totalBuyingCost - totalSaleProceedings) / (totalBuyPosition - totalSellPosition)    
    }

    // Dividend metric
    totalRealizedPnL = realizedPnL
    totalRealizedYield = realizedYield
    breakevenPriceWithDividend = breakevenPrice
    if (order.dividend && order.dividend.length > 0) {
      order.dividend.forEach((dividend) => {
        let amount = dividend.amount_per_share * dividend.position - dividend.commission
        totalDividend += amount
        totalDividendCommission += dividend.commission
        totalRealizedPnL += amount
      })
      if (totalSellTradeBuyingCost > 0.0) {
        totalRealizedYield = totalRealizedPnL / totalSellTradeBuyingCost
      }
      

      if (isActive) {
        breakevenPriceWithDividend = (totalBuyingCost - totalSaleProceedings - totalDividend) / (totalBuyPosition - totalSellPosition)    
      }
    }
    

    return {
      'realized_pnl': realizedPnL,
      'realized_yield': realizedYield,
      'unrealized_pnl': unrealizedPnL,      
      'unrealized_yield': unrealizedYield,

      'total_buying_cost': totalBuyingCost,
      'total_sale_proceedings': totalSaleProceedings,
      'breakeven_price': breakevenPrice,
      
      'is_active': isActive,
      'total_buy_position': totalBuyPosition,
      'total_sell_position': totalSellPosition,
      'active_position': totalBuyPosition - totalSellPosition,
      
      'average_buy_price': totalBuyingCost / totalBuyPosition,
      'average_sell_price': totalSaleProceedings / totalSellPosition,
      
      'total_trade_commission': totalTradeCommission,
      'total_dividend_commission': totalDividendCommission,
      'total_commission': totalTradeCommission + totalDividendCommission,
      
      'total_dividend': totalDividend,      
      
      'realized_pnl_with_dividend': totalRealizedPnL,
      'realized_yield_with_dividend': totalRealizedYield,
      'breakeven_price_with_dividend': breakevenPriceWithDividend,

      'total_sale_trade_buying_cost': totalSellTradeBuyingCost,
      'unrealized_buying_cost': unrealizedBuyingCost,
      'market_value': marketValue
    }
  }

  const _sortTradeAsc = (a, b) => {
    if (a.trade_date !== b.trade_date) {
      return a.trade_date.localeCompare(b.trade_date)
    }
    if (a.buy_sell !== b.buy_sell) {
      return a.buy_sell === 'Buy' ? -1 : 1
    }
    if (a.price !== b.price) {
      return a.price < b.price ? -1 : 1
    }
    return 0    
  }

  const sortTradeDesc = (a, b) => {
    if (a.trade_date !== b.trade_date) {
      return b.trade_date.localeCompare(a.trade_date)
    }
    if (a.buy_sell !== b.buy_sell) {
      return a.buy_sell === 'Buy' ? -1 : 1
    }
    if (a.price !== b.price) {
      return a.price < b.price ? -1 : 1
    }
    return 0    
  }

  const _sortOrder = (a, b) => {
    if (a.analytics.is_active !== b.analytics.is_active) {
      return a.analytics.is_active ? -1 : 1
    }
    if (a.stock.exchange !== b.stock.exchange) {
      return a.stock.exchange.localeCompare(b.stock.exchange)
    }
    if (a.stock.code !== b.stock.code) {
      return a.stock.code.localeCompare(b.stock.code)
    }
    if (a.trade[0].trade_date !== b.trade[0].trade_date) {
      return -1 * (a.trade[0].trade_date.localeCompare(b.trade[0].trade_date))
    }
    return 0
  }

  const sortDividend = (a, b) => {
    return -1 * (a.payment_date.localeCompare(b.payment_date))
  }

  const _findNonMatchingTradeList = (tradeList) => {
    // Sort trade fist, from old to new
    const sortedTradeList = [...tradeList]
    sortedTradeList.sort(_sortTradeAsc)

    const buyPosition = tradeList.filter((item) => item.buy_sell === 'Buy').reduce((total, item) => total + item.position, 0.0)
    const sellPosition = tradeList.filter((item) => item.buy_sell === 'Sell').reduce((total, item) => total + item.position, 0.0)
    // Condition: no active position or no sell position
    if (buyPosition === sellPosition) {
      return []
    }
    if (sellPosition === 0) {
      return sortedTradeList.map((trade) => {
        return {
          'id': trade.id,
          'trade_date': trade.trade_date,
          'position': trade.position,
          'proportion': trade.position
        }
      })
    }

    // Get cumulative buy position, i.e. [100, 200, 300, 400] -> [100, 300, 600, 1000]
    let cumBuyPosList = []
    let cumBuyPos = 0.0
    const buyTradeList = sortedTradeList.filter((item) => item.buy_sell === 'Buy')
    buyTradeList.forEach((trade) => {
      cumBuyPos += trade.position
      cumBuyPosList.push(cumBuyPos)
    })

    // Get start index of the first cumulative buy position < total sell position
    let startIndex = 0
    let startIndexProcessed = false
    cumBuyPosList.forEach((buyPos, index) => {
      if (!startIndexProcessed && buyPos > sellPosition) {
        startIndex = index
        startIndexProcessed = true
      }
    })

    let result = []
    // Condition: startIndex = 0, get the portion from 1st buy position and copy the remaining
    if (startIndex === 0) {
      result.push({
        'id': buyTradeList[startIndex].id,
        'trade_date': buyTradeList[startIndex].trade_date,
        'position': buyTradeList[startIndex].position,
        'proportion': buyTradeList[startIndex].position - sellPosition
      })
      for(let i = startIndex + 1; i < buyTradeList.length; i++) {
        result.push({
          'id': buyTradeList[i].id,
          'trade_date': buyTradeList[i].trade_date,
          'position': buyTradeList[i].position,
          'proportion': buyTradeList[i].position
        })
      }   
    // Condition: startIndex = last, get the portion from last buy position   
    } else if (startIndex === buyTradeList.length - 1) {
      result.push({
        'id': buyTradeList[startIndex].id,
        'trade_date': buyTradeList[startIndex].trade_date,
        'position': buyTradeList[startIndex].position,
        'proportion': cumBuyPosList[startIndex] - sellPosition
      })
    }    
    // Condition: sell position is exactly the same as previous cumulative buy position, 
    // copy all remaining from startIndex 
    else if (cumBuyPosList[startIndex - 1] === sellPosition) {
      for(let i = startIndex; i < buyTradeList.length; i++) {
        result.push({
          'id': buyTradeList[i].id,
          'trade_date': buyTradeList[i].trade_date,
          'position': buyTradeList[i].position,
          'proportion': buyTradeList[i].position
        })
      }     
    // Condition: sell position has some portion in cumulative buy position[startIndex], 
    // copy the portion in startIndex and all remaining after
    } else if (cumBuyPosList[startIndex - 1] < sellPosition) {
      result.push({
        'id': buyTradeList[startIndex].id,
        'trade_date': buyTradeList[startIndex].trade_date,
        'position': buyTradeList[startIndex].position,
        'proportion': cumBuyPosList[startIndex] - sellPosition
      })
      for(let i = startIndex + 1; i < buyTradeList.length; i++) {
        result.push({
          'id': buyTradeList[i].id,
          'trade_date': buyTradeList[i].trade_date,
          'position': buyTradeList[i].position,
          'proportion': buyTradeList[i].position
        })
      }      
    }

    //console.log(result)
    return result
  }

  const _findMatchingTrade = (trade, tradeList) => {
    // Sort trade fist
    const sortedTradeList = [...tradeList]
    sortedTradeList.sort(_sortTradeAsc)

    // For buy, return empty array
    // TODO get starting buy sell
    if (trade.buy_sell === 'Buy') {
      return []
    }

    let result = []
    let cumBuyPosList = []

    // Get previous sell position
    let sellPos = trade.position
    let prevSellPos = 0.0
    let prevSellProcessed = false
    sortedTradeList.forEach((localTrade) => {
      if (!prevSellProcessed && localTrade.buy_sell === 'Sell') {
        if (localTrade.id !== trade.id) {
          prevSellPos += localTrade.position
        } else {
          prevSellProcessed = true
        }        
      }
    })

    //console.log('sell pos', sellPos, 'previous Sell pos', prevSellPos)

    // Get cumulative buy position, i.e. [100, 200, 300, 400] -> [100, 300, 600, 1000]
    let cumBuyPos = 0.0
    const buyTradeList = sortedTradeList.filter((item) => item.buy_sell === 'Buy')
    buyTradeList.forEach((trade) => {
      cumBuyPos += trade.position
      cumBuyPosList.push(cumBuyPos)
    })

    //console.log(cumBuyPosList)

    // Get start index of the first cumulative buy position < previous sell position
    // Get end index of the first cumulative buy position >= previous sell position + current sell position
    let startIndex = 0
    let startIndexProcessed = false
    let endIndex = 0
    let endIndexProcessed = false
    cumBuyPosList.forEach((buyPos, index) => {
      if (!startIndexProcessed && buyPos > prevSellPos) {
        startIndex = index
        startIndexProcessed = true
      }
      if (!endIndexProcessed && buyPos >= (prevSellPos + sellPos)) {
        endIndex = index
        endIndexProcessed = true
      }
    })

    //console.log('start index', startIndex, 'end index' , endIndex)
    // Condition: if startIndex and endIndex are the same, just copy the portion of the particular buy trade
    if (startIndex === endIndex) {
      return [{
        'id': buyTradeList[startIndex].id,
        'trade_date': buyTradeList[startIndex].trade_date,
        'position': buyTradeList[startIndex].position,
        'proportion': sellPos
      }]
    }
    // Condition: if startIndex is 1 element before endIndex, 
    // copy the portion from both buy trade
    if (startIndex + 1 === endIndex) {
      const startPos = cumBuyPosList[startIndex] - prevSellPos
      const endPos = sellPos + prevSellPos - cumBuyPosList[endIndex - 1]

      //console.log('start pos', startPos, 'end pos', endPos)

      return [{
        'id': buyTradeList[startIndex].id,
        'trade_date': buyTradeList[startIndex].trade_date,
        'position': buyTradeList[startIndex].position,
        'proportion': startPos
      }, {
        'id': buyTradeList[endIndex].id,
        'trade_date': buyTradeList[endIndex].trade_date,
        'position': buyTradeList[endIndex].position,
        'proportion': endPos
      }, ]
    }
    // Condition: if startIndex is more than 1 element before endIndex, 
    // copy the portion from start and end trade, and whole trade between
    if (startIndex + 1 < endIndex) {
      const startPos = cumBuyPosList[startIndex] - prevSellPos
      const endPos = sellPos + prevSellPos - cumBuyPosList[endIndex - 1]

      result.push({
        'id': buyTradeList[startIndex].id,
        'trade_date': buyTradeList[startIndex].trade_date,
        'position': buyTradeList[startIndex].position,
        'proportion': startPos
      })
      for (let i = startIndex + 1; i < endIndex; i++) {
        result.push({
          'id': buyTradeList[i].id,
          'trade_date': buyTradeList[i].trade_date,
          'position': buyTradeList[i].position,
          'proportion': buyTradeList[i].position
        })
      }
      result.push({
        'id': buyTradeList[endIndex].id,
        'trade_date': buyTradeList[endIndex].trade_date,
        'position': buyTradeList[endIndex].position,
        'proportion': endPos
      })
      return result
    }
  }

  const _findActiveOrder = (stockId) => {
    const activeOrderList = orderList.filter((item) => item.analytics.is_active && item.stock_id === stockId)
    if (activeOrderList && activeOrderList.length > 0) {
      return activeOrderList[0]
    }
    return null
  }

  const findOrder = (orderId) => {
    const tempList = orderList.filter((item) => item.id === orderId)
    if (tempList && tempList.length > 0) {
      return tempList[0]
    }
    return null
  }

  const _refreshOrder = async (orderId) => {
    let tempList = orderList.filter((item) => item.id !== orderId)
    const order = await _loadSingleFromDB(orderId)

    // If there is no trade and dividend left, delete the order
    if ((order.trade && order.trade.length > 0) || (order.dividend && order.dividend.length > 0)) {
      tempList.push(order)
      tempList.sort(_sortOrder)
    } else {
      const { error } = await (await supabaseClient())
        .from('order_v2')
        .delete()
        .eq('id', orderId)
    }

    setOrderList(tempList)
  }

  const addOrder = async (isFoundStock, newStock, newOrder, newTrade) => {
    let stockResult = null
    if (!isFoundStock) {
      stockResult = await (await supabaseClient())
        .from('stock_v2')
        .insert(newStock)
      setStockList([...stockList, newStock])
    }    

    const orderResult = await (await supabaseClient())
      .from('order_v2')
      .insert(newOrder)
    const tradeResult = await (await supabaseClient())
      .from('trade_v2')
      .insert(newTrade)

    // Refresh state
    await _refreshOrder(newOrder.id)

    if ((stockResult && stockResult.error) || orderResult.error || tradeResult.error) {
      return false
    }
    return true
  }

  const addTrade = async (newTrade) => {
    const { error } = await (await supabaseClient())
      .from('trade_v2')
      .insert(newTrade)

    // Refresh state
    await _refreshOrder(newTrade.order_id)

    if (error) {
      return false
    }
    return true
  }

  const addDividend = async (newDividend) => {
    const { error } = await (await supabaseClient())
      .from('dividend_v2')
      .insert(newDividend)

    // Refresh state
    await _refreshOrder(newDividend.order_id)

    if (error) {
      return false
    }
    return true
  }

  const updateMarketPrice = async (marketPrice, stockId, orderId) => {
    const today = moment().format('YYYY-MM-DD')
    const { error } = await (await supabaseClient())
      .from('stock_v2')
      .update({ 
        'market_price': marketPrice,
        'market_price_date': today})
      .eq('id', stockId)

    await _refreshOrder(orderId)

    if (error) {
      return false
    }
    return true
  }

  const updateNote = async (note, orderId) => {
    const { error } = await (await supabaseClient())
      .from('order_v2')
      .update({ note: note })
      .eq('id', orderId)
    
    await _refreshOrder(orderId)

    if (error) {
      return false
    }
    return true
  }

  const findStock = (searchWord, exchange) => {
    const stock = stockList.filter((s) => s.code.toUpperCase() === searchWord.toUpperCase() && s.exchange === exchange)
    if (stock && stock.length > 0) {
      return stock[0]
    }
    return null
  }

  const deleteTrade = async (orderId, tradeId) => {
    const { error } = await (await supabaseClient())
      .from('trade_v2')
      .delete()
      .eq('id', tradeId)

    // Update state
    await _refreshOrder(orderId)

    if (error) {
      return false
    }
    return true
  }

  const deleteDividend = async (orderId, dividendId) => {
    const { error } = await (await supabaseClient())
      .from('dividend_v2')
      .delete()
      .eq('id', dividendId)

    // Update state
    await _refreshOrder(orderId)

    if (error) {
      return false
    }
    return true
  }

  return <OrderContext.Provider 
    value={{
      isOrderLoaded,
      orderList,
      updateMarketPrice,
      updateNote,
      findOrder,
      addTrade,
      addDividend,
      addOrder,
      findStock,
      isStockLoaded,
      deleteTrade,
      deleteDividend,
      sortTradeDesc,
      sortDividend
    }}>
      {children}
    </OrderContext.Provider>
}

export default OrderContext