import React, { useContext, useEffect, useState } from 'react'
import UserContext from '../context/UserContext'
import RoleContext from '../context/RoleContext'
import { Link, useNavigate } from 'react-router-dom'
import Header from '../components/shared/Header'
import Navbar from '../components/shared/Navbar'
import { toast } from 'react-toastify'
import DataDependencyContext from '../context/DataDependencyContext'

function SelfHostingPage() {
    const {role} = useContext(UserContext)
    const {checkPermission} = useContext(RoleContext)
    const {isQuickDataLoaded} = useContext(DataDependencyContext)
  
    const [isLoading, setIsLoading] = useState(true)
  
    const navigate = useNavigate()
  
    // Load states from Firebase when component mounts
    useEffect(() => {
      if (isQuickDataLoaded) {
        if (checkPermission(role, 'enable-self-hosting-app')) {
            setIsLoading(false)  
        } else {
            toast.error('ERROR: Permission denied')
            navigate('/dashboard')
        }
      }
    }, []);
  
    if (isLoading) {
      return (<>
        <Header title='Self Hosting' />
        <Navbar currentPage='SelfHosting' />
  
        <div className="mb-8">
          Loading ...
        </div>
      </>)
    }
  
    return (
      <>
        <Header title='Self Hosting' />
        <Navbar currentPage='SelfHosting' />
  
        <div className='mb-4'>
            <div className='text-xl font-bold'>Immich</div>
            <Link to='https://immich.jagnito.com' target="_blank">Public Link</Link>
            <div></div>
            <Link to='http://111.220.132.18:2283/' target="_blank">Private Link</Link>
        </div>
        
        <div className='mb-4'>
            <div className='text-xl font-bold'>Trillium Next Notes</div>
            <Link to='https://note.jagnito.com' target="_blank">Public Link</Link>
            <div></div>
            <Link to='http://111.220.132.18:9980/' target="_blank">Private Link</Link>
        </div>

        <div className='mb-4'>
            <div className='text-xl font-bold'>Nginx Proxy Manager</div>
            <Link to='http://111.220.132.18:40080/' target="_blank">Private Link</Link>
            <div></div>
            <Link to='http://111.220.132.18:40081/' target="_blank">Private Admin Link</Link>
        </div>

        <div className='mb-4'>
            <div className='text-xl font-bold'>Invidious</div>
            <Link to='https://youtube.jagnito.com' target="_blank">Public Link</Link>
            <div></div>
            <Link to='http://111.220.132.18:3000/' target="_blank">Private Link</Link>
        </div>

        <div className='mb-4'>
            <div className='text-xl font-bold'>Materialious (Not Used)</div>
            <Link to='https://youtube2.jagnito.com' target="_blank">Public Link</Link>
            <div></div>
            <Link to='http://111.220.132.18:3001/' target="_blank">Private Link</Link>
        </div>

        <div className='mb-4'>
            <div className='text-xl font-bold'>Jellyfin</div>
            <Link to='https://jellyfin.jagnito.com' target="_blank">Public Link</Link>
            <div></div>
            <Link to='http://111.220.132.18:8096/' target="_blank">Private Link</Link>
        </div>
      </>  
    )
}

export default SelfHostingPage
