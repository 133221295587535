import React, { useContext } from 'react'
import TransactionCard from './TransactionCard'
import moment from 'moment'
import CurrencyContext from '../../context/CurrencyContext'
import UserContext from '../../context/UserContext'
import CurrencyLabel from '../shared/CurrencyLabel'
import { LargerFont, SmallerFont } from '../shared/Constants'

function TransactionCardGroup({ date, transactionList }) {
  const {calculateCurrency} = useContext(CurrencyContext)
  const {transactionBaseCurrency} = useContext(UserContext)

  const tempTotal = transactionList.reduce((total, item) => {
    let v = 0.0
    if (!item.is_prepaid) {
      v = calculateCurrency(transactionBaseCurrency, item.currency, moment().format('YYYY-MM-DD'), item.amount)
    }
    return item.type === 'Income' ? total + v : total - v
  }, 0.0)      
  
  return (
    <div className='mb-10'>
      <div className='mb-4'>
        <div className={`${LargerFont} font-bold`}>{date}</div>
        <CurrencyLabel 
          currency={transactionBaseCurrency} 
          value={tempTotal} 
          maximumFractionDigits={0}
          theme='gray'
          className={`${SmallerFont}`}>Total: </CurrencyLabel>
      </div>
      
      {transactionList && transactionList.map((item, index) => (
        <TransactionCard key={index} transaction={item} />
      ))}
    </div>
  )
}

export default TransactionCardGroup