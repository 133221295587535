import React, { useContext } from 'react'
import UserContext from '../../context/UserContext'
import { SmallerFont } from './Constants'

function ResponsiveTable({className, headerList, dataTable, option = {
  size: 'default',
  color: 'default',
}}) {
  const {colorTheme} = useContext(UserContext)

  const size = option.size
  const color = option.color
  const showBorder = dataTable && dataTable.length > 0 && dataTable[0].length > 5
  let cellPadding = 'px-4 py-1 md:py-2'
  let alternativeRowBg = colorTheme.secondary_bg_color
  let fontSize = ''
  let textColor = ''

  if (size === 'small') {
    fontSize = SmallerFont
    cellPadding = 'px-3 py-1'
  }

  if (color === 'gray') {
    textColor = colorTheme.secondary_text_color
  }  

  return (
    <>
      <table className={`${fontSize} ${textColor} ${className} w-full ${colorTheme.default_bg_color} border ${colorTheme.secondary_border_color}`}>
        {headerList && headerList.length > 0 && (
          <thead className='hidden md:table-header-group'>
            <tr className={`${alternativeRowBg} `}>
              {headerList.map((item, index) => {
                return <th className={`${cellPadding} text-left [&:not(:first-child)]:text-right`} key={index}>{item}</th>
              })}
            </tr>
          </thead>
        )}
        {dataTable && dataTable.length > 0 && (
          <tbody>
            {dataTable.map((row, rowIndex) => {
              return (
                <tr key={rowIndex} className={`flex flex-col md:table-row even:${colorTheme.card_bg_color} odd:${alternativeRowBg}  md:odd:${colorTheme.card_bg_color} md:even:${alternativeRowBg}`}>
                  {row.map((col, colIndex) => {
                    return (
                      <td key={colIndex} className={`${cellPadding} [&:not(:first-child)]:text-right`}>
                        <div className={`grid grid-cols-2 ${showBorder ? `border-dashed border-b ${colorTheme.secondary_border_color} pb-0.5 md:border-none md:pb-0` : ''}`}>
                          <div className='font-bold md:hidden text-left'>{headerList[colIndex]}</div>
                          <div className="ml-auto md:ml-0 md:col-span-2">{col}</div>
                        </div>

                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        )}
      </table>
    </>
  )
}

export default ResponsiveTable