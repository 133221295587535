import React from 'react'
import { createContext, useContext, useState, useEffect } from 'react'
import UserContext from './UserContext'
import SupabaseContext from './SupabaseContext'

const TransactionTagContext = createContext()

export const TransactionTagProvider = ({children}) => {
  const {supabaseClient} = useContext(SupabaseContext)
  const {userId} = useContext(UserContext);
  const [transactionTagList, setTransactionTagList] = useState([])
  const [isTransactionTagLoaded, setIsTransactionTagLoaded] = useState(false)

  const fetchTransactionTag = async () => {
    const { data, error } = await (await supabaseClient())
      .from('view_transactiontag_v2')
      .select(`
        id,
        name,
        is_income,
        is_expense,
        is_active,
        is_highlight,
        type,
        analytics_period_summary,
        analytics_monthly_summary,
        transaction_count
      `)
      .eq('user_id', userId)

    data.sort(_sort)
    setTransactionTagList(data)

    // console.log('Transaction Tag Context', data)

    setIsTransactionTagLoaded(true)
  }

  useEffect(() => {
    if (userId) {
      fetchTransactionTag()    
    }    
  }, [userId])

  const _loadSingleFromDB = async (tagId) => {
    const { data, error } = await (await supabaseClient())
      .from('view_transactiontag_v2')
      .select(`
        id,
        name,
        is_income,
        is_expense,
        is_active,
        is_highlight,
        type,
        analytics_period_summary,
        analytics_monthly_summary,
        transaction_count
      `)
      .eq('user_id', userId)
      .eq('id', tagId)
    
    return data[0]
  }

  const findTransactionTag = (id) => {
    const result = transactionTagList.filter((a) => a.id === id)
    if (result && result.length > 0) {
      return result[0]
    }
    return null
  }

  const _sort = (a, b) => {
    if (a.name !== b.name) {
      return a.name.localeCompare(b.name)
    }
    return 0    
  }

  /*const filterAccount = (activeOnly) => {
    let tempList = [...accountList]
    if (activeOnly) {
      tempList = tempList.filter((t) => t.is_active)
    }
    tempList.sort(_sort)
    return tempList
  }*/

  const addTransactionTag = async (formDetails) => {
    let id = formDetails.id;

    const {error} = await (await supabaseClient())
      .from('transactionTag_v2')
      .insert(formDetails)

    const newT = await _loadSingleFromDB(id)

    let tempList = [...transactionTagList.filter((a) => a.id !== id), newT]
    tempList.sort(_sort)        
    setTransactionTagList(tempList)

    if (error) {
      return false
    } else {
      return true
    }
  }

  const updateTransactionTag = async (formDetails) => {
    const id = formDetails.id;

    const {error} = await (await supabaseClient())
      .from('transactionTag_v2 ')
      .update(formDetails)
      .eq('id', id)

    const newT = await _loadSingleFromDB(id)

    let tempList = [...transactionTagList.filter((a) => a.id !== id), newT]
    tempList.sort(_sort)        
    setTransactionTagList(tempList)

    if (error) {
      return false
    } else {
      return true
    }
  }

  const deleteTransactionTag = async (id) => {
    const {error} = await (await supabaseClient())
      .from('transactionTag_v2 ')
      .delete()
      .eq('user_id', userId)
      .eq('id', id)

    let tempList = [...transactionTagList.filter((a) => a.id !== id)]
    tempList.sort(_sort)        
    setTransactionTagList(tempList)

    // TODO Update transaction with removed tag

    if (error) {
      return false
    } else {
      return true
    }
  }


  return <TransactionTagContext.Provider 
    value={{
      isTransactionTagLoaded,
      transactionTagList,
      findTransactionTag,
      addTransactionTag,
      updateTransactionTag,
      deleteTransactionTag
    }}>
      {children}
    </TransactionTagContext.Provider>
}

export default TransactionTagContext