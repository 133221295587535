import React from 'react'
import { createContext, useContext, useState, useEffect } from 'react'
import UserContext from './UserContext'
import AccountContext from './AccountContext'
import SupabaseContext from './SupabaseContext'
import moment from 'moment'

const TransactionContext = createContext()

export const TransactionProvider = ({children}) => {
  const {supabaseClient} = useContext(SupabaseContext)
  const {userId} = useContext(UserContext);
  const {getAccountName} = useContext(AccountContext)
  const [transactionList, setTransactionList] = useState([])
  const [isTransactionLoaded, setIsTransactionLoaded] = useState(false)
  const [isFullTransactionDataLoaded, setIsFullTransactionDataLoaded] = useState(false)
  const [isPartialTransactionDataLoaded, setIsPartialTransactionDataLoaded] = useState(false)

  const selectStatement = `
        id,
        type,
        transaction_date,
        note,
        amount,
        is_special,
        is_prepaid,
        account_v2 (
          id,
          name,
          institution,
          currency,
          display_name
        ),
        transaction_r_transactionTag_v2 (
          transactionTag_v2 (
            id,
            name
          )
        )
      `;

  const _fetchPartialTransaction = async () => {
    const lastMonthStartDate = moment().startOf('month').subtract(1, 'months').format("YYYY-MM-DD")
    const { data, error } = await (await supabaseClient())
        .from('transaction_v2')
        .select(selectStatement)
        .eq('user_id', userId)
        .gte('transaction_date', lastMonthStartDate)
      
    if (data) {
      // console.log('Transaction Context', data)
      setTransactionList(data)
      setIsPartialTransactionDataLoaded(true)
    } else {
      setTransactionList([])
    }
    
    setIsTransactionLoaded(true)
  }

  const _fetchMonthlyTransaction = async () => {
    const lastMonthStartDate = moment().startOf('month').subtract(1, 'months').format("YYYY-MM-DD")
    const initialStartDate = moment().year(2019).month(0).date(1)

    let newTransactionList = []

    for (let d = initialStartDate; d.format("YYYY-MM-DD") < lastMonthStartDate; d = d.add(1, 'months')) {
      const nextMonth = d.clone().add(1, 'months').format('YYYY-MM-DD')  // work on clone only
      const thisMonth = d.format("YYYY-MM-DD")
      const { data, error } = await (await supabaseClient())
        .from('transaction_v2')
        .select(selectStatement)
        .eq('user_id', userId)
        .lt('transaction_date', nextMonth)
        .gte('transaction_date', thisMonth)


      if (data) {
        newTransactionList = [...newTransactionList, ...data]
      }
    }

    setTransactionList([...transactionList, ...newTransactionList])
    setIsFullTransactionDataLoaded(true)    
  }

  useEffect(() => {
    if (userId) {
      _fetchPartialTransaction()    
    }    
  }, [userId])

  useEffect(() => {
    if (isPartialTransactionDataLoaded && !isFullTransactionDataLoaded) {
      _fetchMonthlyTransaction()  
    }    
  }, [isPartialTransactionDataLoaded, isFullTransactionDataLoaded])

  const _loadSingleFromDB = async (transactionId) => {
    const { data, error } = await (await supabaseClient())
      .from('transaction_v2')
      .select(selectStatement)
      .eq('user_id', userId)
      .eq('id', transactionId)
    
    return data[0]
  }

  const findTransaction = (id) => {
    const result = transactionList.filter((a) => a.id === id)
    if (result && result.length > 0) {
      return result[0]
    }
    return null
  }

  const _sort = (a, b) => {
    if (a.transaction_date !== b.transaction_date) {
      return -1 * (a.transaction_date.localeCompare(b.transaction_date))
    }
    if (a.amount !== b.amount) {
      return a.amount < b.amount ? 1 : a.amount === b.amount ? 0 : -1
    }
    return 0    
  }

  const _addToDb = async (newTransaction) => {
    const { error } = await (await supabaseClient())
      .from('transaction_v2')
      .insert(newTransaction)

    if (error) {
      return false
    } 
    return true
  }

  const _addTagListToDb = async (newTagList) => {
    const { error } = await (await supabaseClient())
      .from('transaction_r_transactionTag_v2')
      .insert(newTagList)

      if (error) {
        return false
      } 
    return true
  }

  const _deleteFromDb = async (id) => {
    const { error } = await (await supabaseClient())
        .from('transaction_v2')
        .delete()
        .eq('id', id)
    
    if (error) {
      return false
    }
    return true
  }

  const addTransaction = async (formDetails, formTagList) => {
    const successAdd = await _addToDb(formDetails)
    const successAddTag = await _addTagListToDb(formTagList)

    if (successAdd && successAddTag) {
      const newT = await _loadSingleFromDB(formDetails.id)
      let tempList = [newT]
      if (transactionList.length > 0) {
        tempList = [...transactionList.filter((a) => a.id !== formDetails.id), newT]
      }
      setTransactionList(tempList)

      return true
    }    
    return false
  }

  const updateTransaction = async (newTransaction, newTagList) => {
    const oldId = newTransaction.id

    const successDelete = await _deleteFromDb(oldId)
    const successAdd = await _addToDb(newTransaction)
    const successAddTag = await _addTagListToDb(newTagList)

    if (successAdd && successAddTag && successDelete) {
      const newT = await _loadSingleFromDB(newTransaction.id)
      let tempList = [newT]
      if (transactionList && transactionList.length > 0) {
        tempList = [...transactionList.filter((t) => t.id !== oldId), newT]
      }
      setTransactionList(tempList)
      return true
    }
    return false
  }

  const deleteTransaction = async (id) => {
    const successDelete = await _deleteFromDb(id)

    let tempList = []
    if (transactionList && transactionList.length > 0) {
      tempList = [...transactionList.filter((a) => a.id !== id)]
    }
    setTransactionList(tempList)

    // TODO Update transaction with removed tag

    if (successDelete) {
      return true
    } else {
      return false
    }
  }

  const createViewModel = (transaction) => {
    let tempTagNameList = []
    let tempTagIdList = []
    if (transaction.transaction_r_transactionTag_v2 && transaction.transaction_r_transactionTag_v2.length > 0) {
      tempTagNameList = transaction.transaction_r_transactionTag_v2.map((tag) => tag.transactionTag_v2.name)
      tempTagNameList.sort()

      tempTagIdList = transaction.transaction_r_transactionTag_v2.map((tag) => tag.transactionTag_v2.id)
    }        

    return {
      id: transaction.id,
      transaction_date: transaction.transaction_date,
      type: transaction.type,
      is_special: transaction.is_special,
      is_prepaid: transaction.is_prepaid,
      note: transaction.note,
      account_name: getAccountName(transaction.account_v2),
      currency: transaction.account_v2.currency,
      amount: transaction.amount,
      tagNameList: tempTagNameList,
      tagIdList: tempTagIdList,
      attribute: transaction.type + ' ' + (transaction.is_special ? 'special' : '') + ' ' + (transaction.is_prepaid ? 'prepaid' : '')
    }
  }

  return <TransactionContext.Provider 
    value={{
      isTransactionLoaded,
      transactionList,
      findTransaction,
      addTransaction,
      updateTransaction,
      deleteTransaction,
      createViewModel,
      isFullTransactionDataLoaded
    }}>
      {children}
    </TransactionContext.Provider>
}

export default TransactionContext